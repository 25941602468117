import { reducer as voxeetReducer } from "@voxeet/react-components"
import React from "react"
import thunkMidleware from "redux-thunk"
import { combineReducers, createStore, applyMiddleware } from "redux"

import { ConferenceRoom, VoxeetProvider } from "@voxeet/react-components"

import Loading from './Loading';

// Import Style
import "@voxeet/react-components/dist/voxeet-react-components.css"

const reducers = combineReducers({
  voxeet: voxeetReducer,
})

window.addEventListener("message", messageHandler, false);

function messageHandler(event) {
    const { action, key, value } = event.data
    if (action === 'save'){
      window.localStorage.setItem(key, value)
    } else if (action === 'get') {
      event.source.postMessage({
        action: 'returnData',
        key: key,
        value: window.localStorage.getItem(key)
      }, '*')
    } else if (action === 'getId') {
      event.source.postMessage({
        action: 'returnId',
        key: key,
        value: sessionStorage.getItem("conferenceId")
      }, '*');
      sessionStorage.removeItem("conferenceId");
    } else if (action === 'remove') {
      window.localStorage.removeItem(key);
    }
}

const configureStore = () =>
  createStore(reducers, applyMiddleware(thunkMidleware));

let settings = {
  consumerKey: "",
  consumerSecret: "",
  conferenceAlias: "Sample1",
  isWidget: false,
  userInfo: {
    name: 'John Doe',
    externalId: 1
  },
  constraints: {audio: true, video: false},
  displayActions: ["mute", "video", "share", "recording", "live", "attendees"],
  shareActions: ["screenshare"],
  preConfig: false,
  liveRecordingEnabled: false,
  autoRecording: false,
  isManualKickAllowed: false,
  kickOnHangUp: false,
  isAdmin: false,
  confkey: 'leaveConf'
}

class App extends React.Component {
  constructor(props) {
    super(props);
    try {
      let data = this.props.location.search.replace('?','');
      this.state = JSON.parse(atob(data));
    } catch (error) {
      
    }
    if (this.state.apiKey) {      
      settings.consumerKey = this.state.apiKey;
    }
    if (this.state.apiSecret) {
      settings.consumerSecret = this.state.apiSecret;
    }
    if (this.state.room) {      
      settings.conferenceAlias = this.state.room;
    }
    if (this.state.userInfo) {
      settings.userInfo = this.state.userInfo;
    }
    if (this.state.constraints) {
      settings.constraints = this.state.constraints;
    }
    if (this.state.displayActions) {
      settings.displayActions = this.state.displayActions;
    }
    if (this.state.shareActions) {
      settings.shareActions = this.state.shareActions;
    }
    if (this.state.preConfig) {
      settings.preConfig = this.state.preConfig;
    }
    if (this.state.liveRecordingEnabled) {
      settings.liveRecordingEnabled = this.state.liveRecordingEnabled;
    }
    if (this.state.autoRecording) {
      settings.autoRecording = this.state.autoRecording;
    }
    if (this.state.isManualKickAllowed) {
      settings.isManualKickAllowed = this.state.isManualKickAllowed;
    }
    if (this.state.kickOnHangUp) {
      settings.kickOnHangUp = this.state.kickOnHangUp;
    }
    if (this.state.isAdmin) {
      settings.isAdmin = this.state.isAdmin;
    }
    if (this.state.confkey) {
      settings.confkey = this.state.confkey;
    }
  }

  onCall() {
    localStorage.removeItem('leaveConf');
    localStorage.removeItem(settings.confkey);
  }

  leaveCall() {
    document.documentElement.className = 'leaveConf';
    localStorage.setItem('leaveConf',  true);
    localStorage.setItem(settings.confkey,  true);
  }

  render() {
    return (
      <VoxeetProvider store={configureStore()}>
        <ConferenceRoom
          autoJoin
          consumerKey={settings.consumerKey}
          consumerSecret={settings.consumerSecret}
          conferenceAlias={settings.conferenceAlias}
          isWidget={settings.isWidget}
          userInfo={settings.userInfo}
          logo={settings.logo}
          displayActions={settings.displayActions}
          shareActions={settings.shareActions}
          constraints={settings.constraints}
          preConfig={settings.preConfig}
          autoRecording={settings.autoRecording}
          liveRecordingEnabled={settings.liveRecordingEnabled}
          isManualKickAllowed={settings.isManualKickAllowed}
          kickOnHangUp={settings.kickOnHangUp}
          isAdmin={settings.isAdmin}
          loadingScreen={Loading}
          handleOnLeave={this.leaveCall}
          handleOnConnect={this.onCall}
        />
      </VoxeetProvider>
    )
  }
}
export default App