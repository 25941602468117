import React from "react"

class Loading extends React.Component {
    render() {
      return <div className="voxeet-loading-message-container">
                <div className="voxeet-loading-center-container">
                    {/* <div class="voxeet-loading-logo-container">
                        <img src="https://rdov2testapi.resolvedisputes.online/uploads/logo/logo_1587558527.png">
                    </div> */}
                    <div id="loader-container">
                        <div className="loader"></div>
                    </div>
                    <div className="voxeet-loading-info-container">
                        Joining Conference. Please stand by
                        <span className="one">.</span>
                        <span className="two">.</span>
                        <span className="three">.</span>
                    </div>
                </div>
            </div>;
    }
}
export default Loading